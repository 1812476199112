import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ROLE_ENDPOINT } from 'src/constants/api-endpoints';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  roles: [],
  role: {},
  selectedRoleId: null,
  openModal: false,
};

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ROLES
    getRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },

    // CREATE ROLE
    createRoleSuccess(state, action) {
      state.roles = [...state.roles, { ...action.payload }];
    },

    // UPDATE ROLE
    updateRoleSuccess(state, action) {
      state.isLoading = false;
      state.roles = state.roles.map((role) => {
        if (role.id === action.payload.id) {
          return {
            ...action.payload,
          };
        }
        return role;
      });
    },

    // DELETE ROLE
    deleteRoleSuccess(state, action) {
      const roleId = action.payload;
      state.roles = state.roles.filter((role) => role.id !== roleId);
    },

    // SELECT ROLE
    selectRole(state, action) {
      const roleId = action.payload;
      state.openModal = true;
      state.selectedRoleId = roleId;
      state.role = state.roles.find((role) => role?.id === roleId);
    },

    // OPEN MODAL
    onOpenModal(state) {
      state.openModal = true;
    },

    // CLOSE MODAL
    onCloseModal(state) {
      state.openModal = false;
      state.selectedRoleId = null;
      state.role = {};
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onOpenModal, onCloseModal, selectRole } = slice.actions;

// ----------------------------------------------------------------------

export function getAllRoles() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(ROLE_ENDPOINT);
      dispatch(slice.actions.getRolesSuccess(response.data.roles));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createRole(newRole) {
  return async (dispatch) => {
    const response = await axios.post(ROLE_ENDPOINT, newRole);
    dispatch(slice.actions.createRoleSuccess({ ...newRole, id: response?.data?.roleId }));
  };
}

// ----------------------------------------------------------------------

export function updateRole(roleId, roleDetails) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.put(`${ROLE_ENDPOINT}/${roleId}`, roleDetails);
    dispatch(slice.actions.updateRoleSuccess(response.data.role));
  };
}

// ----------------------------------------------------------------------

export function deleteRole(roleId) {
  return async (dispatch) => {
    await axios.delete(`${ROLE_ENDPOINT}/${roleId}`);
    dispatch(slice.actions.deleteRoleSuccess(roleId));
  };
}
