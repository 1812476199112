import qs from 'qs';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ANIMAL_ENDPOINT } from 'src/constants/api-endpoints';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  animals: [],
  animal: {},
  selectedAnimalId: null,
  openModal: false,
};

const slice = createSlice({
  name: 'animal',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ANIMALS
    getAnimalsSuccess(state, action) {
      state.isLoading = false;
      state.animals = action.payload;
    },

    // CREATE ANIMAL
    createAnimalSuccess(state, action) {
      state.animals = [...state.animals, { ...action.payload }];
    },

    // UPDATE ANIMAL
    updateAnimalSuccess(state, action) {
      state.isLoading = false;
      state.animals = state.animals.map((animal) => {
        if (animal.id === action.payload.id) {
          return {
            ...action.payload,
          };
        }
        return animal;
      });
    },

    // DELETE ANIMAL
    deleteAnimalSuccess(state, action) {
      const animalId = action.payload;
      state.animals = state.animals.filter((animal) => animal.id !== animalId);
    },

    // SELECT ANIMAL
    selectAnimal(state, action) {
      const animalId = action.payload;
      state.openModal = true;
      state.selectedRoleId = animalId;
      state.animal = state.animals.find((animal) => animal?.id === animalId);
    },

    // OPEN MODAL
    onOpenModal(state) {
      state.openModal = true;
    },

    // CLOSE MODAL
    onCloseModal(state) {
      state.openModal = false;
      state.selectedRoleId = null;
      state.animal = {};
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onOpenModal, onCloseModal, selectAnimal } = slice.actions;

// ----------------------------------------------------------------------

export function getAllAnimals(filters) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = qs.stringify(filters);
      const response = await axios.get(`${ANIMAL_ENDPOINT}?${query}`);
      dispatch(slice.actions.getAnimalsSuccess(response.data.animals));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createAnimal(newAnimal) {
  return async (dispatch) => {
    const response = await axios.post(ANIMAL_ENDPOINT, newAnimal);
    dispatch(slice.actions.createAnimalSuccess({ ...newAnimal, id: response?.data?.animalId }));
  };
}

// ----------------------------------------------------------------------

export function updateAnimal(animalId, animalDetails) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.put(`${ANIMAL_ENDPOINT}/${animalId}`, animalDetails);
    dispatch(slice.actions.updateAnimalSuccess(response.data.animal));
  };
}

// ----------------------------------------------------------------------

export function deleteAnimal(animalId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${ANIMAL_ENDPOINT}/${animalId}`);
      dispatch(slice.actions.deleteAnimalSuccess(animalId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
