import { debounce } from 'lodash';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { useSnackbar } from 'src/components/snackbar';

export function useQuery(query, deps) {
  /* eslint-disable react-hooks/exhaustive-deps */

  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    cache: null,
    error: null,
    loading: true,
  });

  // Debounce the actual request in the case of, eg. a search filter changing
  // while the user is typing.
  const doFetch = useMemo(
    () =>
      debounce(
        /* eslint-disable-next-line no-shadow */
        async ({ setState, enqueueSnackbar, query }) => {
          try {
            const cache = await query();

            setState((s) => ({ ...s, cache, error: null, loading: false }));
          } catch (error) {
            setState((s) => ({
              ...s,
              cache: null,
              error: error.message ?? error.error,
              loading: false,
            }));
            enqueueSnackbar(error.message ?? error.error ?? 'Something went wrong', {
              variant: 'error',
            });
          }
        },
        300
      ),
    []
  );

  const invalidate = useCallback(() => {
    setState((s) => ({ ...s, loading: true }));

    return doFetch({
      setState,
      enqueueSnackbar,
      query,
    });
  }, [setState, enqueueSnackbar, query]);

  useEffect(() => void invalidate(), deps);

  // Forego the debounce on initial load. (This removes an initial delay when
  // first loading data.)
  useEffect(() => void doFetch.flush(), []);

  return useMemo(() => ({ ...state, invalidate }), [state, invalidate]);
  /* eslint-enable react-hooks/exhaustive-deps */
}

export function usePaginatedQuery(query, deps) {
  const { cache, loading, invalidate } = useQuery(query, deps);

  return useMemo(
    () => ({
      items: cache?.items ?? [],
      count: cache?.count ?? 0,
      loading,
      invalidate,
    }),
    [cache, loading, invalidate]
  );
}
