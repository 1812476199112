import { combineReducers } from 'redux';
// slices
import calendarReducer from './slices/calendar';
import userReducer from './slices/user';
import roleReducer from './slices/role';
import animalReducer from './slices/animal';
import membershipReducer from './slices/membership';
import reportsReducer from './slices/reports';

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  calendar: calendarReducer,
  user: userReducer,
  role: roleReducer,
  animal: animalReducer,
  membership: membershipReducer,
  reports: reportsReducer,
});

export { rootReducer };
