import { paths } from 'src/routes/paths';

const { VITE_API_BASE_URL, VITE_ASSETS_API, REACT_APP_ENV, DEV } = import.meta.env;

// API
// ----------------------------------------------------------------------

export const API_BASE_URL = VITE_API_BASE_URL;
export const ASSETS_API = VITE_ASSETS_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root;

export const ENV = REACT_APP_ENV;
export const isDevelopment = DEV;

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};
