import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { OCCASION_ENDPOINT } from 'src/constants/api-endpoints';
import { OCCASION_TYPES_VS_COLOR } from 'src/constants/occasion';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  occasions: [],
  occasion: {},
  openModal: false,
  selectedOccasionId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE OCCASION
    createOccasionSuccess(state, action) {
      const newOccasion = {
        ...action.payload,
        color: OCCASION_TYPES_VS_COLOR[action.payload.type],
        textColor: OCCASION_TYPES_VS_COLOR[action.payload.type],
      };
      state.occasions = [...state.occasions, newOccasion];
    },

    // UPDATE OCCASION
    updateOccasionSuccess(state, action) {
      state.isLoading = false;
      state.occasions = state.occasions.map((occasion) => {
        if (occasion.id === action.payload.id) {
          return {
            ...action.payload,
            color: OCCASION_TYPES_VS_COLOR[action.payload.type],
            textColor: OCCASION_TYPES_VS_COLOR[action.payload.type],
          };
        }
        return occasion;
      });
    },

    // DELETE OCCASION
    deleteOccasionSuccess(state, action) {
      const occasionId = action.payload;
      state.occasions = state.occasions.filter((occasion) => occasion.id !== occasionId);
    },

    // SELECT OCCASION
    selectOccasion(state, action) {
      const occasionId = action.payload;
      state.openModal = true;
      state.selectedOccasionId = occasionId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.openModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    onOpenModal(state) {
      state.openModal = true;
    },

    // CLOSE MODAL
    onCloseModal(state) {
      state.openModal = false;
      state.selectedOccasionId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onOpenModal, onCloseModal, selectOccasion, selectRange } = slice.actions;

// ----------------------------------------------------------------------

export function createOccasion(newOccasion, invalidate) {
  return async (dispatch) => {
    const response = await axios.post(OCCASION_ENDPOINT, newOccasion);
    dispatch(slice.actions.createOccasionSuccess(response.data.occasion));
    if (invalidate) invalidate();
  };
}

// ----------------------------------------------------------------------

export function updateOccasion(occasionId, occasion, invalidate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${OCCASION_ENDPOINT}/${occasionId}`, occasion);
      dispatch(slice.actions.updateOccasionSuccess(response.data.occasion));
      if (invalidate) invalidate();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteOccasion(occasionId, invalidate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${OCCASION_ENDPOINT}/${occasionId}`);
      dispatch(slice.actions.deleteOccasionSuccess(occasionId));
      if (invalidate) invalidate();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
