import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard, PermissionBasedGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';
import { MODULE_TYPES, MODULES } from 'src/constants';

// ----------------------------------------------------------------------

const OverviewPage = lazy(() => import('src/pages/dashboard/overview/main'));
const UserPage = lazy(() => import('src/pages/dashboard/user/main'));
const SettingsPage = lazy(() => import('src/pages/dashboard/settings/main'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar/main'));
const EventsPage = lazy(() => import('src/pages/dashboard/events/main'));
const EventDetailsPage = lazy(() => import('src/pages/dashboard/events/details'));
const MembershipsPage = lazy(() => import('src/pages/dashboard/memberships/main'));
const DonorsPage = lazy(() => import('src/pages/dashboard/donor/main'));
const DonorDetailsPage = lazy(() => import('src/pages/dashboard/donor/details'));
const DonationsPage = lazy(() => import('src/pages/dashboard/donations/main'));
const ImportDonationsPage = lazy(() => import('src/pages/dashboard/donations/import'));
const AppealsPage = lazy(() => import('src/pages/dashboard/appeals/main'));
const AppealDetailsPage = lazy(() => import('src/pages/dashboard/appeals/details'));
const CampaignsPage = lazy(() => import('src/pages/dashboard/campaigns/main'));
const CampaignDetailsPage = lazy(() => import('src/pages/dashboard/campaigns/details'));
const TagsPage = lazy(() => import('src/pages/dashboard/tags/main'));
const ReportsPage = lazy(() => import('src/pages/dashboard/reports/main'));
const ReportsDetailsPage = lazy(() => import('src/pages/dashboard/reports/details'));
const UserProfilePage = lazy(() => import('src/pages/dashboard/profile/main'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <PermissionBasedGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </PermissionBasedGuard>
      </AuthGuard>
    ),
    children: [
      { element: <OverviewPage />, index: true },
      { path: 'users', element: <UserPage module={MODULE_TYPES[MODULES.USER]} /> },
      { path: 'settings', element: <SettingsPage module={MODULE_TYPES[MODULES.SETTING]} /> },
      {
        path: 'user-profile',
        element: <UserProfilePage module={MODULE_TYPES[MODULES.USER_PROFILE]} />,
      },
      { path: 'calendar', element: <CalendarPage module={MODULE_TYPES[MODULES.CALENDAR]} /> },
      { path: 'events', element: <EventsPage module={MODULE_TYPES[MODULES.EVENT]} /> },
      { path: 'events/:id', element: <EventDetailsPage module={MODULE_TYPES[MODULES.EVENT]} /> },
      {
        path: 'events/edit/:id',
        element: <EventDetailsPage module={MODULE_TYPES[MODULES.EVENT]} editing />,
      },
      {
        path: 'memberships',
        element: <MembershipsPage module={MODULE_TYPES[MODULES.MEMBERSHIP]} />,
      },
      { path: 'donors', element: <DonorsPage module={MODULE_TYPES[MODULES.DONOR]} /> },
      { path: 'donors/:id', element: <DonorDetailsPage module={MODULE_TYPES[MODULES.DONOR]} /> },
      {
        path: 'donors/edit/:id',
        element: <DonorDetailsPage module={MODULE_TYPES[MODULES.DONOR]} editing />,
      },
      { path: 'donations', element: <DonationsPage module={MODULE_TYPES[MODULES.DONATION]} /> },
      {
        path: 'donations/import',
        element: <ImportDonationsPage module={MODULE_TYPES[MODULES.DONATION]} />,
      },
      {
        path: 'donations/:id',
        element: <DonationsPage module={MODULE_TYPES[MODULES.DONATION]} view />,
      },
      { path: 'appeals', element: <AppealsPage module={MODULE_TYPES[MODULES.APPEAL]} /> },
      {
        path: 'appeals/:id',
        element: <AppealDetailsPage module={MODULE_TYPES[MODULES.APPEAL]} />,
      },
      {
        path: 'appeals/edit/:id',
        element: <AppealDetailsPage module={MODULE_TYPES[MODULES.APPEAL]} editing />,
      },
      { path: 'campaigns', element: <CampaignsPage module={MODULE_TYPES[MODULES.CAMPAIGN]} /> },
      {
        path: 'campaigns/:id',
        element: <CampaignDetailsPage module={MODULE_TYPES[MODULES.CAMPAIGN]} />,
      },
      {
        path: 'campaigns/edit/:id',
        element: <CampaignDetailsPage module={MODULE_TYPES[MODULES.CAMPAIGN]} editing />,
      },
      { path: 'tags', element: <TagsPage module={MODULE_TYPES[MODULES.TAG]} /> },
      { path: 'reports', element: <ReportsPage module={MODULE_TYPES[MODULES.REPORT]} /> },
      {
        path: 'reports/:id',
        element: <ReportsDetailsPage module={MODULE_TYPES[MODULES.REPORT]} />,
      },
    ],
  },
];
