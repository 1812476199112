import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import AuthClassicLayout from 'src/layouts/auth/classic';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const LoginPage = lazy(() => import('src/pages/auth/login'));
const RegisterPage = lazy(() => import('src/pages/auth/register'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password'));
const VerifyPage = lazy(() => import('src/pages/auth/verify'));

// ----------------------------------------------------------------------

const authJwt = {
  path: '',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <LoginPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <RegisterPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },

    {
      path: 'forgot-password',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <ForgotPasswordPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },

    {
      path: 'verify',
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <VerifyPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },

    // {
    //   element: (
    //     <CompactLayout>
    //       <Outlet />
    //     </CompactLayout>
    //   ),
    //   children: [
    //     { path: 'forgot-password', element: <ForgotPasswordPage /> },
    //     // { path: 'new-password', element: <NewPasswordClassicPage /> },
    //     // { path: 'verify', element: <VerifyClassicPage /> },
    //   ],
    // },
  ],
};

export const authRoutes = [
  {
    path: '',
    children: [authJwt],
  },
];
