import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

const AsyncCacheContext = createContext();

export const useAsyncCache = (key) => {
  const ctx = useContext(AsyncCacheContext);
  if (!ctx) {
    throw new Error('Cannot get AsyncCacheContext, did you forget an <AsyncCacheProvider />?');
  }

  const { cached, invalidateKey } = ctx;

  return {
    cached: (fetchFunction) => cached(key, fetchFunction),
    invalidate: () => invalidateKey(key),
  };
};

AsyncCacheProvider.propTypes = {
  children: PropTypes.node,
};

export function AsyncCacheProvider({ children }) {
  const [store, setStore] = useState({});
  const [pending, setPending] = useState({}); // Track pending fetches

  const invalidateKey = useCallback((key) => {
    setStore((prevStore) => {
      const { [key]: _, ...rest } = prevStore;
      void _;
      return rest;
    });

    // Remove the key from the pending requests
    setPending((prev) => {
      const newPending = { ...prev };
      delete newPending[key];
      return newPending;
    });
  }, []);

  const cached = useCallback(
    async (key, fetchFunction) => {
      if (key in store) {
        return store[key];
      }

      if (pending[key]) {
        return pending[key];
      }

      const fetchPromise = fetchFunction().then((value) => {
        setStore((prevStore) => ({
          ...prevStore,
          [key]: value,
        }));

        setPending((prev) => {
          const newPending = { ...prev };
          delete newPending[key];
          return newPending;
        });

        return value;
      });

      setPending((prev) => ({
        ...prev,
        [key]: fetchPromise,
      }));

      return fetchPromise;
    },
    [store, pending]
  );

  const value = useMemo(() => ({ cached, invalidateKey }), [cached, invalidateKey]);

  return <AsyncCacheContext.Provider value={value}>{children}</AsyncCacheContext.Provider>;
}
