// TODO: remove this
/** @deprecated */
export function useMockedUser() {
  const user = {
    id: '8864c717-587d-472a-929a-8e5f298024da-0',
    displayName: 'Rachel Anderson',
    email: 'demo@buildingourzoo.com',
    password: 'demo1234',
    phoneNumber: '+1 780-555-1212',
    country: 'Canada',
    address: '90210 Broadway Blvd',
    state: 'Alberta',
    city: 'Edmonton',
    zipCode: 'T6X1H9',
    about: 'About text...',
    role: 'admin',
    isPublic: true,
  };

  return { user };
}
