import _omit from 'lodash/omit';

export const COLORS = {
  PRIMARY_MAIN: '#00AB55',
  PRIMARY_LIGHT: '#33D02B',
  PRIMARY_DARKER: '#002766',
  INFO_MAIN: '#1890FF',
  SUCCESS_MAIN: '#54D62C',
  WARNING_MAIN: '#FFC107',
  ERROR_MAIN: '#FF4842',
  INFO_DARKER: '#04297A',
  ERROR_DARKER: '#7A0C2E',
};

// const COLOR_OPTIONS = [
//   COLORS.PRIMARY_MAIN, // theme.palette.primary.main,
//   COLORS.INFO_MAIN, // theme.palette.info.main,
//   COLORS.SUCCESS_MAIN, // theme.palette.success.main,
//   COLORS.WARNING_MAIN, // theme.palette.warning.main,
//   COLORS.ERROR_MAIN, // theme.palette.error.main
//   COLORS.INFO_DARKER, // theme.palette.info.darker
//   COLORS.ERROR_DARKER, // theme.palette.error.darker
// ];

export const COLOR_OPTIONS_IN_RGB = {
  [COLORS.PRIMARY_MAIN]: 'rgba(0, 171, 85, 0.2)', // theme.palette.primary.main,
  [COLORS.INFO_MAIN]: 'rgba(24, 144, 255, 0.2)', // theme.palette.info.main,
  [COLORS.SUCCESS_MAIN]: 'rgba(84, 214, 44, 0.2)', // theme.palette.success.main,
  [COLORS.WARNING_MAIN]: 'rgba(255, 193, 7, 0.2)', // theme.palette.warning.main,
  [COLORS.ERROR_MAIN]: 'rgba(255, 72, 66, 0.2)', // theme.palette.error.main
  [COLORS.INFO_DARKER]: 'rgba(4, 41, 122, 0.2)', // theme.palette.info.darker
  [COLORS.ERROR_DARKER]: 'rgba(122, 12, 46, 0.2)', // theme.palette.error.darker
};

export const OCCASION_TYPES = {
  EVENT: 'EVENT',
  RENEWALS: 'RENEWALS',
  ANIMAL_BIRTHDAY: 'ANIMAL_BIRTHDAY',
  GRANT_DEADLINES: 'GRANT_DEADLINES',
  OTHER: 'OTHER',
};

export const OCCASION_TYPES_VS_COLOR = {
  [OCCASION_TYPES.EVENT]: COLORS.PRIMARY_MAIN,
  [OCCASION_TYPES.RENEWALS]: COLORS.INFO_MAIN,
  [OCCASION_TYPES.ANIMAL_BIRTHDAY]: COLORS.SUCCESS_MAIN,
  [OCCASION_TYPES.GRANT_DEADLINES]: COLORS.WARNING_MAIN,
  [OCCASION_TYPES.OTHER]: COLORS.INFO_DARKER,
  'task': COLORS.INFO_DARKER,
  'appeal': COLORS.ERROR_DARKER,
  'special event': COLORS.PRIMARY_LIGHT,
  'campaign': COLORS.PRIMARY_DARKER,
};

export const OCCASION_TYPES_VS_LABEL = {
  [OCCASION_TYPES.EVENT]: 'Events',
  [OCCASION_TYPES.RENEWALS]: 'Renewals',
  [OCCASION_TYPES.ANIMAL_BIRTHDAY]: 'Animal Birthdays',
  [OCCASION_TYPES.GRANT_DEADLINES]: 'Grant Deadlines',
  [OCCASION_TYPES.OTHER]: 'Random Other Stuff',
};

export const OCCASION_TYPE_OPTIONS = [
  ...Object.keys(_omit(OCCASION_TYPES, [OCCASION_TYPES.EVENT, OCCASION_TYPES.ANIMAL_BIRTHDAY])),
].map((type) => ({
  label: OCCASION_TYPES_VS_LABEL[type],
  value: type,
  color: OCCASION_TYPES_VS_COLOR[type],
}));

export const OCCASION_TYPE_FILTER_OPTIONS = [...Object.keys(OCCASION_TYPES)].map((type) => ({
  label: OCCASION_TYPES_VS_LABEL[type],
  value: type,
  color: OCCASION_TYPES_VS_COLOR[type],
}));
