import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import SvgColor from 'src/components/svg-color';
import { MODULE_TYPES, MODULE_TYPES_VS_TITLES, MODULES } from 'src/constants';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  calendar: icon('ic_calendar'),
  dashboard: icon('ic_dashboard'),
  donation: icon('ic_donation'),
  campaign: icon('ic_campaign'),
  appeal: icon('ic_campaign'),
  event: icon('ic_event'),
  donor: icon('ic_donor'),
  membership: icon('ic_membership'),
  report: icon('ic_report'),
  user: icon('ic_user'),
  tag: icon('ic_tag'),
  setting: icon('ic_setting'),
};

export const NAV_ICONS = ICONS;

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          {
            title: MODULE_TYPES_VS_TITLES[MODULE_TYPES[MODULES.HOME]],
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
            module: MODULE_TYPES[MODULES.HOME],
          },
          {
            title: MODULE_TYPES_VS_TITLES[MODULE_TYPES[MODULES.CALENDAR]],
            path: paths.dashboard.calendar,
            icon: ICONS.calendar,
            module: MODULE_TYPES[MODULES.CALENDAR],
          },
          {
            title: MODULE_TYPES_VS_TITLES[MODULE_TYPES[MODULES.DONATION]],
            path: paths.dashboard.donations.root,
            icon: ICONS.donation,
            module: MODULE_TYPES[MODULES.DONATION],
          },
          {
            title: MODULE_TYPES_VS_TITLES[MODULE_TYPES[MODULES.CAMPAIGN]],
            path: paths.dashboard.campaigns.root,
            icon: ICONS.campaign,
            module: MODULE_TYPES[MODULES.CAMPAIGN],
          },
          {
            title: MODULE_TYPES_VS_TITLES[MODULE_TYPES[MODULES.APPEAL]],
            path: paths.dashboard.appeals.root,
            icon: ICONS.campaign,
            module: MODULE_TYPES[MODULES.APPEAL],
          },
          {
            title: MODULE_TYPES_VS_TITLES[MODULE_TYPES[MODULES.EVENT]],
            path: paths.dashboard.events.root,
            icon: ICONS.event,
            module: MODULE_TYPES[MODULES.EVENT],
          },
          {
            title: MODULE_TYPES_VS_TITLES[MODULE_TYPES[MODULES.DONOR]],
            path: paths.dashboard.donors.root,
            icon: ICONS.donor,
            module: MODULE_TYPES[MODULES.DONOR],
          },
          {
            title: MODULE_TYPES_VS_TITLES[MODULE_TYPES[MODULES.MEMBERSHIP]],
            path: paths.dashboard.memberships,
            icon: ICONS.membership,
            module: MODULE_TYPES[MODULES.MEMBERSHIP],
          },
          {
            title: MODULE_TYPES_VS_TITLES[MODULE_TYPES[MODULES.REPORT]],
            path: paths.dashboard.reports.root,
            icon: ICONS.report,
            module: MODULE_TYPES[MODULES.REPORT],
          },
          {
            title: MODULE_TYPES_VS_TITLES[MODULE_TYPES[MODULES.USER]],
            path: paths.dashboard.users,
            icon: ICONS.user,
            module: MODULE_TYPES[MODULES.USER],
          },
          {
            title: MODULE_TYPES_VS_TITLES[MODULE_TYPES[MODULES.TAG]],
            path: paths.dashboard.tags,
            icon: ICONS.tag,
            module: MODULE_TYPES[MODULES.TAG],
          },
          {
            title: MODULE_TYPES_VS_TITLES[MODULE_TYPES[MODULES.SETTING]],
            path: paths.dashboard.settings,
            icon: ICONS.setting,
            module: MODULE_TYPES[MODULES.SETTING],
          },
        ],
      },
    ],
    []
  );

  return data;
}
