import PropTypes from 'prop-types';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import axios, { endpoints } from 'src/utils/axios';

import * as api from 'src/backend';

import { AuthContext } from './auth-context';
import { setSession, isValidToken } from './utils';
import { MODULE_TYPES, MODULES } from 'src/constants';

// ----------------------------------------------------------------------
/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */
// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
  permittedModules: [],
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
      permittedModules: action.payload.permittedModules,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(endpoints.auth.me);

        const { user } = response.data;

        const permittedModules = user?.permissions?.map((permission) => permission.module) || [
          ...Object.values(MODULE_TYPES),
        ];

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...user,
              accessToken,
            },
            permittedModules: [
              ...permittedModules,
              // no permissions defined for these modules
              MODULE_TYPES[MODULES.HOME],
              MODULE_TYPES[MODULES.USER_PROFILE],
            ],
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const user = await api.auth.login({ email, password });
    setSession(user.token);

    const permittedModules = user?.permissions?.map((permission) => permission.module) || [
      ...Object.values(MODULE_TYPES),
    ];

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          permittedModules: [
            ...permittedModules,
            // no permissions defined for these modules
            MODULE_TYPES[MODULES.HOME],
            MODULE_TYPES[MODULES.APPEAL],
            MODULE_TYPES[MODULES.USER_PROFILE],
          ],
        },
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    localStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
  }, []);

  const updatePassword = useCallback(async ({ user_id, old_password, new_password }) => {
    await api.auth.updateLoggedInUserPassword({ user_id, old_password, new_password });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      permittedModules: state.permittedModules,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
      updatePassword,
      invalidate: initialize,
    }),
    [
      login,
      logout,
      register,
      updatePassword,
      state.user,
      status,
      state.permittedModules,
      initialize,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
