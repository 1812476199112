import { fetchAPI, parseBody } from './common';

export async function login({ email, password }) {
  const res = await fetchAPI('/auth/login', 'post', { email, password });
  const body = await parseBody(res);

  return body.user;
}

export async function sendResetPasswordOTP({ email }) {
  const res = await fetchAPI('/auth/reset-password-otp', 'post', { email });
  const body = await parseBody(res);

  return body.message;
}

export async function resetPasswordWithOTP({ otp, password, email }) {
  const res = await fetchAPI('/auth/reset-password', 'post', {
    otp,
    password,
    email,
  });

  const body = await parseBody(res);
  return body.message;
}

export async function updateLoggedInUserPassword(params) {
  const res = await fetchAPI('/auth/update-password', 'patch', { ...params });
  const body = await parseBody(res);

  return body.message;
}
