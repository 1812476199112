import axios from '../utils/axios';

export async function fetchAPI(route, method, data) {
  const headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  };

  return axios(
    {
      method,
      url: route,
      data,
    },
    {
      headers,
    }
  );
}

export async function parseBody(res) {
  const isOk = res.status >= 200 && res.status < 400 && res.data?.success;

  if (!isOk) {
    throw new Error(res.data?.error ?? res.statusText);
  }

  return res.data;
}
