import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { USER_ENDPOINT } from 'src/constants/api-endpoints';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  user: {},
  selectedUserId: null,
  openModal: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      const users = action?.payload?.filter((user) => !user?.is_deleted);
      state.isLoading = false;
      state.users = users;
    },

    // GET USER BY ID
    getUserDetailsByIdSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    // CREATE USER
    createUserSuccess(state, action) {
      state.users = [...state.users, { ...action.payload }];
    },

    // UPDATE USER
    updateUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    // DELETE USER
    deleteUserSuccess(state, action) {
      const userId = action.payload;
      state.users = state.users.filter((user) => user.id !== userId);
    },

    // SELECT USER
    selectUser(state, action) {
      const userId = action.payload;
      state.openModal = true;
      state.selectedUserId = userId;
      state.user = state.users.find((user) => user?.id === userId);
    },

    // OPEN MODAL
    onOpenModal(state) {
      state.openModal = true;
    },

    // CLOSE MODAL
    onCloseModal(state) {
      state.openModal = false;
      state.selectedUserId = null;
      state.user = {};
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onOpenModal, onCloseModal, selectUser } = slice.actions;

// ----------------------------------------------------------------------

export function getAllUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(USER_ENDPOINT);
      dispatch(slice.actions.getUsersSuccess(response.data.items));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserDetailsById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(`${USER_ENDPOINT}/${id}`);
    dispatch(slice.actions.getUserDetailsByIdSuccess(response.data));
  };
}

// ----------------------------------------------------------------------

export function createUser(newUser) {
  return async (dispatch) => {
    const response = await axios.post(USER_ENDPOINT, newUser);
    dispatch(slice.actions.createUserSuccess(response.data.user));
  };
}

// ----------------------------------------------------------------------

export function updateUser(userId, userDetails, callback) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.put(`${USER_ENDPOINT}/${userId}`, userDetails);
    dispatch(slice.actions.updateUserSuccess(response.data.user));
    callback();
  };
}

// ----------------------------------------------------------------------

export function deleteUser(userId) {
  return async (dispatch) => {
    await axios.delete(`${USER_ENDPOINT}/${userId}`);
    dispatch(slice.actions.deleteUserSuccess(userId));
  };
}
