import 'src/global.css';

// slick-carousel
// eslint-disabled import/no-extraneous-dependencies
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Provider as ReduxProvider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { StyledChart } from './components/chart';

// ----------------------------------------------------------------------

import Router from 'src/routes/sections';

import ThemeProvider from 'src/theme';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';

import { AuthProvider } from 'src/auth/context/jwt';

// redux
import { store } from './redux/store';
import { AsyncCacheProvider } from './utils/async-cache';

import * as Sentry from '@sentry/react';

// ----------------------------------------------------------------------

Sentry.init({
  dsn: 'https://84aafb09263816858f664f06c4a5623d@o89831.ingest.us.sentry.io/4506933124399104',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/api.zoological\.app/],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <AsyncCacheProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AuthProvider>
          <ReduxProvider store={store}>
            <SettingsProvider
              defaultSettings={{
                themeMode: 'light', // 'light' | 'dark'
                themeDirection: 'ltr', //  'rtl' | 'ltr'
                themeContrast: 'default', // 'default' | 'bold'
                themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                themeStretch: false,
              }}
            >
              <ThemeProvider>
                <MotionLazy>
                  <SnackbarProvider>
                    {/* <StyledChart /> // TODO Check later on */}
                    <SettingsDrawer />
                    <ProgressBar />
                    <Router />
                  </SnackbarProvider>
                </MotionLazy>
              </ThemeProvider>
            </SettingsProvider>
          </ReduxProvider>
        </AuthProvider>
      </LocalizationProvider>
    </AsyncCacheProvider>
  );
}
