import { useAsyncCache } from 'src/utils/async-cache';
import * as Sentry from '@sentry/react';
import * as api from 'src/backend';
import { useQuery } from './use-query';

export const placeholder = '/assets/images/placeholder.png';

/**
 * Fetch (and cache) an image by it's unique id.
 *
 * Returns either a blob URL or null if the image could not be fetched (eg, id
 * is null, or the image was deleted.)
 *
 * @param {string?} id
 * @returns {{ cache: string?, loading: boolean, error: Error? } }}
 */
export default function useImage(id) {
  const cache = useAsyncCache(`image/${id}`);

  const {
    cache: url,
    loading,
    error,
  } = useQuery(
    () =>
      cache.cached(async () => {
        if (!id) {
          return null;
        }

        try {
          const image = await api.file.get(id);
          const url = URL.createObjectURL(image);
          return url;
        } catch (error) {
          Sentry.captureException(error);
          return null;
        }
      }),
    [id]
  );

  return { url, loading, error };
}
