import qs from 'qs';
import { API_BASE_URL } from 'src/config-global';

/**
 * Upload a file to an S3 bucket. Returns a unique id which can be used to
 * retrive that file later.
 *
 * @param {File} file
 * @returns {Promise<string>}
 */
export async function upload(file) {
  const config = {
    name: file?.name,
    type: file?.type,
  };

  // As the payload is not JSON, we use the lower-level fetchAPI
  const token = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const blobData = new Blob([await file.arrayBuffer()], { type: 'application/octet-stream' });
  const res = await fetch(`${API_BASE_URL}file?${qs.stringify(config)}`, {
    headers,
    method: 'post',
    body: blobData,
  });

  const body = await res.json();
  if (body?.error || res.status !== 200) {
    throw new Error(body?.message ?? res.statusText);
  }

  return body.id;
}

/**
 * Download an uploaded file by it's unique id.
 *
 * @param {string} id
 * @returns {Promise<Blob>}
 */
export async function get(fileId) {
  // As the resulting payload is not JSON, we use the lower-level fetchAPI
  const token = window.localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const res = await fetch(`${API_BASE_URL}file/${fileId}`, {
    headers,
    method: 'get',
  });

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  return res.blob();
}
