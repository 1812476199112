import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { RouterLink } from 'src/routes/components';

import { useAuthContext } from '../hooks';

import { ForbiddenIllustration } from 'src/assets/illustrations';

import { varBounce, MotionContainer } from 'src/components/animate';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import { MODULE_TYPES, MODULES, ROUTES_VS_MODULE_TYPES } from 'src/constants';

// ----------------------------------------------------------------------

export default function PermissionBasedGuard({ children, sx }) {
  const { permittedModules } = useAuthContext();
  const { pathname } = useLocation();
  const pathArr = pathname.split('/');
  const currentModule = pathArr?.[2]
    ? ROUTES_VS_MODULE_TYPES?.[pathArr?.[2]]
    : MODULE_TYPES[MODULES.HOME];

  if (!isEmpty(permittedModules) && !permittedModules.includes(currentModule)) {
    return (
      <Container
        component={MotionContainer}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          ...sx,
        }}
      >
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>

        <Box>
          <Button component={RouterLink} href="/" size="large" variant="contained">
            Go to Home
          </Button>
        </Box>
      </Container>
    );
  }

  return <>{children} </>;
}

PermissionBasedGuard.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
