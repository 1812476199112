import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { MEMBERSHIP_ENDPOINT, MEMBERSHIP_TYPES_ENDPOINT } from 'src/constants/api-endpoints';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  memberships: [],
  membership: {},
  membershipTypes: [],
  membershipType: {},
  selectedMembership: null,
  selectedMembershipType: null,
  openMembershipModal: false,
  openMembershipTypeModal: false,
  activeMembershipTypes: [],
};

const slice = createSlice({
  name: 'membership',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MEMBERSHIPS
    getMembershipsSuccess(state, action) {
      state.isLoading = false;
      state.memberships = action.payload;
    },

    // GET ALL MEMBERSHIP Groups
    getMembershipTypesSuccess(state, action) {
      state.isLoading = false;
      state.membershipTypes = action.payload;
      state.activeMembershipTypes = action.payload.filter(
        (membershipType) => membershipType?.status === 'active'
      );
    },

    // CREATE MEMBERSHIP
    createMembershipSuccess(state, action) {
      state.memberships = [...state.memberships, { ...action.payload }];
    },

    // CREATE MEMBERSHIP Group
    createMembershipTypeSuccess(state, action) {
      state.membershipTypes = [...state.membershipTypes, { ...action.payload }];
    },

    // UPDATE MEMBERSHIP
    updateMembershipSuccess(state, action) {
      state.isLoading = false;
      state.memberships = state.memberships.map((membership) => {
        if (membership.id === action.payload.id) {
          return {
            ...action.payload,
          };
        }
        return membership;
      });
    },

    // UPDATE MEMBERSHIP Group
    updateMembershipTypeSuccess(state, action) {
      state.isLoading = false;
      state.membershipTypes = state.membershipTypes.map((membershipType) => {
        if (membershipType.id === action.payload.id) {
          return {
            ...action.payload,
          };
        }
        return membershipType;
      });
    },

    // DELETE MEMBERSHIP
    deleteMembershipSuccess(state, action) {
      const membershipId = action.payload;
      state.memberships = state.memberships.filter((membership) => membership.id !== membershipId);
    },

    // DELETE MEMBERSHIP Group
    deleteMembershipTypeSuccess(state, action) {
      const membershipTypeId = action.payload;
      state.membershipTypes = state.membershipTypes.filter(
        (membershipType) => membershipType.id !== membershipTypeId
      );
    },

    // SELECT MEMBERSHIP
    selectMembership(state, action) {
      const membershipId = action.payload;
      state.openMembershipModal = true;
      state.selectedMembership = membershipId;
      state.membership = state.memberships.find((membership) => membership?.id === membershipId);
    },

    // SELECT MEMBERSHIP Group
    selectMembershipType(state, action) {
      const membershipTypeId = action.payload;
      state.openMembershipTypeModal = true;
      state.selectedMembershipType = membershipTypeId;
      state.membershipType = state.membershipTypes.find(
        (membershipType) => membershipType?.id === membershipTypeId
      );
    },

    // OPEN MEMBERSHIP MODAL
    onOpenMembershipModal(state) {
      state.openMembershipModal = true;
    },

    // OPEN MEMBERSHIP Group MODAL
    onOpenMembershipTypeModal(state) {
      state.openMembershipTypeModal = true;
    },

    // CLOSE MEMBERSHIP MODAL
    onCloseMembershipModal(state) {
      state.openMembershipModal = false;
      state.selectedMembership = null;
      state.membership = {};
    },

    // CLOSE MEMBERSHIP Group MODAL
    onCloseMembershipTypeModal(state) {
      state.openMembershipTypeModal = false;
      state.selectedMembershipType = null;
      state.membershipType = {};
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onOpenMembershipModal,
  onCloseMembershipModal,
  selectMembership,
  onOpenMembershipTypeModal,
  onCloseMembershipTypeModal,
  selectMembershipType,
} = slice.actions;

// ----------------------------------------------------------------------

export function getAllMemberships() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(MEMBERSHIP_ENDPOINT);
      dispatch(slice.actions.getMembershipsSuccess(response.data.memberships));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllMembershipsTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(MEMBERSHIP_TYPES_ENDPOINT);
      dispatch(slice.actions.getMembershipTypesSuccess(response.data.membershipTypes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createMembership(newMembership) {
  return async (dispatch) => {
    const response = await axios.post(MEMBERSHIP_ENDPOINT, newMembership);
    dispatch(slice.actions.createMembershipSuccess(response?.data?.membership));
  };
}

// ----------------------------------------------------------------------

export function createMembershipType(newMembershipType) {
  return async (dispatch) => {
    const response = await axios.post(MEMBERSHIP_TYPES_ENDPOINT, newMembershipType);
    dispatch(slice.actions.createMembershipTypeSuccess(response?.data?.membershipType));
  };
}

// ----------------------------------------------------------------------

export function updateMembership(membershipId, membershipDetails) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.put(`${MEMBERSHIP_ENDPOINT}/${membershipId}`, membershipDetails);
    dispatch(slice.actions.updateMembershipSuccess(response.data.membership));
  };
}

// ----------------------------------------------------------------------

export function updateMembershipType(membershipTypeId, membershipTypeDetails) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.put(
      `${MEMBERSHIP_TYPES_ENDPOINT}/${membershipTypeId}`,
      membershipTypeDetails
    );
    dispatch(slice.actions.updateMembershipTypeSuccess(response.data.membershipType));
  };
}

// ----------------------------------------------------------------------

export function deleteMembership(membershipId) {
  return async (dispatch) => {
    await axios.delete(`${MEMBERSHIP_ENDPOINT}/${membershipId}`);
    dispatch(slice.actions.deleteMembershipSuccess(membershipId));
  };
}

// ----------------------------------------------------------------------

export function deleteMembershipType(membershipTypeId) {
  return async (dispatch) => {
    await axios.delete(`${MEMBERSHIP_TYPES_ENDPOINT}/${membershipTypeId}`);
    dispatch(slice.actions.deleteMembershipTypeSuccess(membershipTypeId));
  };
}
