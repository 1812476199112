export const API_FAILURE_MESSAGE = 'Something went wrong!';
export const strongPasswordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/;

export const MODULES = {
  HOME: 'HOME',
  CALENDAR: 'CALENDAR',
  DONATION: 'DONATION',
  CAMPAIGN: 'CAMPAIGN',
  APPEAL: 'APPEAL',
  EVENT: 'EVENT',
  DONOR: 'DONOR',
  MEMBERSHIP: 'MEMBERSHIP',
  REPORT: 'REPORT',
  USER: 'USER',
  TAG: 'TAG',
  SETTING: 'SETTING',
  USER_PROFILE: 'USER_PROFILE',
};

export const MODULE_TYPES = {
  [MODULES.HOME]: 'home',
  [MODULES.CALENDAR]: 'calendar',
  [MODULES.DONATION]: 'donation',
  [MODULES.CAMPAIGN]: 'campaign',
  [MODULES.APPEAL]: 'appeal',
  [MODULES.EVENT]: 'event',
  [MODULES.DONOR]: 'donor',
  [MODULES.MEMBERSHIP]: 'membership',
  [MODULES.REPORT]: 'report',
  [MODULES.USER]: 'user',
  [MODULES.TAG]: 'tag',
  [MODULES.SETTING]: 'setting',
  [MODULES.USER_PROFILE]: 'user-profile',
};

export const MODULE_TYPES_VS_TITLES = {
  [MODULE_TYPES[MODULES.HOME]]: 'Home',
  [MODULE_TYPES[MODULES.CALENDAR]]: 'Calendar',
  [MODULE_TYPES[MODULES.DONATION]]: 'Donations',
  [MODULE_TYPES[MODULES.CAMPAIGN]]: 'Campaign',
  [MODULE_TYPES[MODULES.APPEAL]]: 'Appeals',
  [MODULE_TYPES[MODULES.EVENT]]: 'Special Events',
  [MODULE_TYPES[MODULES.DONOR]]: 'Donors',
  [MODULE_TYPES[MODULES.MEMBERSHIP]]: 'Memberships',
  [MODULE_TYPES[MODULES.REPORT]]: 'Reports',
  [MODULE_TYPES[MODULES.USER]]: 'Users',
  [MODULE_TYPES[MODULES.TAG]]: 'Tags And Lists',
  [MODULE_TYPES[MODULES.SETTING]]: 'Settings',
};

export const ROUTES_VS_MODULE_TYPES = {
  calendar: MODULE_TYPES[MODULES.CALENDAR],
  donations: MODULE_TYPES[MODULES.DONATION],
  campaigns: MODULE_TYPES[MODULES.CAMPAIGN],
  appeals: MODULE_TYPES[MODULES.APPEAL],
  events: MODULE_TYPES[MODULES.EVENT],
  donors: MODULE_TYPES[MODULES.DONOR],
  memberships: MODULE_TYPES[MODULES.MEMBERSHIP],
  reports: MODULE_TYPES[MODULES.REPORT],
  users: MODULE_TYPES[MODULES.USER],
  tags: MODULE_TYPES[MODULES.TAG],
  settings: MODULE_TYPES[MODULES.SETTING],
  'user-profile': MODULE_TYPES[MODULES.USER_PROFILE],
};
